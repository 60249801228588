import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 24px;
    > span.or {
        position: absolute;
        left: -32px;
        top: 64px;
        ${typography.title.S};
    }
`;

export const StyledTerminalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 12px;
`;

export const StyledFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    // max-width: 640px;
`;

export const StyledLabel = styled.span`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledFieldDescription = styled.p`
    display: flex;
    flex-direction: column;
    ${typography.text.M};
`;

export const StyledButtonContainer = styled.div`
    display: flex;
`;
