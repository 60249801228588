import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IError } from '@/types';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { getWsLabByUuidThunk } from '@/store/reducers/LabSlice/AsyncThanks';

export const wsLabAddTimeThunk = createAsyncThunk<
    void,
    string,
    { rejectValue: IError }
>(
    'templateSlice/wsLabAddTimeThunk',
    async (uuid, { rejectWithValue, dispatch }) => {
        try {
            await apiWorkshopLabClient.post(
                `/api/v1/workshoplabs/${uuid}/add_time/`,
                {}
            );
            await dispatch(getWsLabByUuidThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
