import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import {
    StyledWhom,
    StyledTitle,
    StyledColumnsContainer,
    StyledColumn,
    StyledSubtitleLarge,
    StyledTitleMiddle,
    StyledBody,
    ButtonContainer,
} from './styled';
import whom_img from '../../assets/SVGTitles/secondPagesTtotles/whom.svg';

export const Whom = forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const navigate = useNavigate();
    return (
        <StyledWhom ref={ref} id="whom">
            <StyledTitle src={whom_img} alt="whom" />
            <StyledColumnsContainer>
                <StyledColumn>
                    <StyledSubtitleLarge>
                        Defbox – For Those Who Want to Advance as Cybersecurity
                        Professionals
                    </StyledSubtitleLarge>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>Students</StyledTitleMiddle>
                    <StyledBody>
                        Get real skills beyond theory. Learn practical skills
                        you can use in real jobs. Our labs use real tools and
                        scenarios to help you succeed in securing a job in the
                        cybersecurity field.
                    </StyledBody>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>Engineers</StyledTitleMiddle>
                    <StyledBody>
                        Keep growing your skills and get challenges. Stay
                        up-to-date with the latest technologies and threats.
                        Feel free to share your ideas for new labs. .
                    </StyledBody>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>
                        Professors and Managers
                    </StyledTitleMiddle>
                    <StyledBody>
                        Customize learning for your needs. For Professors: Get
                        access to our instructor tools and create labs tailored
                        to your course. For Managers: We help conduct technical
                        interviews, onboarding, and internal assessments.
                        Contact us through the form below to collaborate.
                    </StyledBody>
                </StyledColumn>
            </StyledColumnsContainer>
            <ButtonContainer>
                <Button
                    onClick={() => navigate('/auth/write_to_us')}
                    variant="secondary"
                >
                    Get in touch
                </Button>
                <span>
                    Do you represent a company or university? Leave us your
                    email and we will get back to you
                    <br />
                    with our options for SOCs and universities.
                </span>
            </ButtonContainer>
        </StyledWhom>
    );
});
