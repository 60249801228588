import { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import { Input, Button } from '@/components';
import { LAB_TYPE } from '@/constants';
import { useAppDispatch } from '@/hooks/redux';
import { LabContext } from '@/pages/LabWithScheme/context';
import { SideBarContext } from '@/components/SideBar/context';
import {
    checkTasksWithFlagWsThunk,
    checkTasksWithFlagThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { SHAPE, IButton as IButtonProps } from '@/components/Button/types';
import { StyledContainer } from './styled';

interface IProps {
    uuid?: string;
    cardId?: number;
    disabled?: boolean;
    buttonProps?: IButtonProps;
    isCloseSidebar?: boolean;
}

export const InputFlag = ({
    uuid,
    cardId,
    disabled,
    buttonProps,
    isCloseSidebar,
}: IProps) => {
    const dispatch = useAppDispatch();
    const { setOpenCellId } = useContext(SideBarContext);
    const { type: labType } = useContext(LabContext);
    const submitHandler = (flag: string) => {
        if (uuid && cardId && flag) {
            if (labType === LAB_TYPE.DEFAULT) {
                dispatch(checkTasksWithFlagThunk({ uuid, cardId, flag }));
            }
            if (labType === LAB_TYPE.WORKSHOP) {
                dispatch(checkTasksWithFlagWsThunk({ uuid, cardId, flag }));
            }
            if (isCloseSidebar) {
                setOpenCellId(undefined);
            }
        }
    };
    return (
        <Form onSubmit={submitHandler}>
            {({ handleSubmit, pristine }) => {
                return (
                    <StyledContainer onSubmit={handleSubmit}>
                        <Field name="flag">
                            {({ input: { onChange, value } }) => {
                                return (
                                    <Input
                                        onChange={onChange}
                                        value={value}
                                        style={{ minWidth: '240px' }}
                                        disabled={disabled}
                                        placeholder="Enter the flag you found"
                                    />
                                );
                            }}
                        </Field>
                        <Button
                            view={SHAPE.GHOST}
                            disabled={pristine || disabled}
                            type="submit"
                            tooltip={
                                disabled
                                    ? {
                                          text: 'First you need to run the deploy',
                                      }
                                    : undefined
                            }
                            {...buttonProps}
                        >
                            Check
                        </Button>
                    </StyledContainer>
                );
            }}
        </Form>
    );
};
