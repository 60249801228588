import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IInitialState } from '../types';
import { getTasksWithoutLoaderThunk, getTasksWithoutLoadersWsThunk } from '.';

export const checkTasksWithFlagThunk = createAsyncThunk<
    void,
    { uuid: string; cardId: number; flag: string },
    { rejectValue: IError }
>(
    'taskSlice/checkTasksWithFlag',
    async function ({ uuid, cardId, flag }, { rejectWithValue, dispatch }) {
        console.log('f: ', flag);
        try {
            await apiClient.post(
                `/api/v1/labs/${uuid}/tasks/${cardId}/check_answer/`,
                flag
            );
            dispatch(getTasksWithoutLoaderThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const checkTasksWithFlagBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(checkTasksWithFlagThunk.pending, (state) => {
        state.checkTaskState.fetching = true;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWithFlagThunk.fulfilled, (state) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWithFlagThunk.rejected, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error.code = action.payload?.code;
        state.checkTaskState.error.message = action.payload?.message;
    });
};

export const checkTasksWithFlagWsThunk = createAsyncThunk<
    void,
    { uuid: string; cardId: number; flag: string },
    { rejectValue: IError }
>(
    'taskSlice/checkTasksWithFlagWs',
    async function ({ uuid, cardId, flag }, { rejectWithValue, dispatch }) {
        try {
            await apiWorkshopLabClient.post(
                `/api/v1/labs/${uuid}/tasks/${cardId}/check_answer/`,
                flag
            );
            dispatch(getTasksWithoutLoadersWsThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const checkTasksWithFlagWsBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(checkTasksWithFlagWsThunk.pending, (state) => {
        state.checkTaskState.fetching = true;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWithFlagWsThunk.fulfilled, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWithFlagWsThunk.rejected, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error.code = action.payload?.code;
        state.checkTaskState.error.message = action.payload?.message;
    });
};
