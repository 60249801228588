import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { authSlice } from '@/store/reducers/AuthUserSlice';
import { refreshAuthThunk } from '@/store/reducers/AuthUserSlice/AsyncThunks';
import { RootState, AppDispatch } from '@/store/store';
import { ITokensData } from '@/types';

export type TNoticeType = 'success' | 'error' | 'process' | 'fetching';

export interface INoticeSystemError {
    code: string;
    message: string;
}

export interface INoticeModal {
    type: TNoticeType;
    text: string;
    content?: string;
}

let store: ToolkitStore<RootState>;
let lastRefreshTimestamp = 0;

export const injectStore = (_store: ToolkitStore<RootState>) => {
    store = _store;
};

interface IAPIClient extends AxiosInstance {
    isRestoringSession: boolean;
}

interface CustomRequestConfig extends AxiosRequestConfig {
    requestName?: string;
}

const tokenToInterceptor = () => {
    const { access, refresh } = store.getState().authReducer;
    const tokenObj = {
        access: access ?? 'not_token',
        refresh: refresh ?? 'not_token',
    };

    if (!access || !refresh) {
        const localTokens: ITokensData | null =
            typeof localStorage.getItem('token') === 'string'
                ? JSON.parse(localStorage.getItem('token') as string)
                : null;
        if (!!localTokens) {
            authSlice.actions.authFetchingAccessAction({
                ...localTokens,
            });

            tokenObj.access = localTokens.access;
            tokenObj.refresh = localTokens.refresh;
        }
    }
    return tokenObj;
};

const AXIOS = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
}) as IAPIClient;

AXIOS.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const currentTime = Date.now();
        
        if (error.response.status === 403 && !originalRequest._retry) {
            if (currentTime - lastRefreshTimestamp > 3000) {
                originalRequest._retry = true;
                const dispatch = store.dispatch as AppDispatch;
                try {
                    const { refresh } = tokenToInterceptor();
                    const resp = await dispatch(refreshAuthThunk({ refresh }));
                    originalRequest.headers.Authorization = `Bearer ${resp.payload}`;
                    lastRefreshTimestamp = currentTime;
                    return axios(originalRequest);
                } catch (err) {
                    dispatch(authSlice.actions.exitAuthAction());
                }
            }
        }
        return Promise.reject(error);
    }
);

AXIOS.interceptors.request.use(
    (config) => {
        const { access } = tokenToInterceptor();
        if (access && access=='not_token') {
            config = {
                ...config,
                headers: {
                    ...config.headers,
                    'Content-Type': 'application/json',
                },
            };
            return config;

        }
        else if (access) {
            config = {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${access}`,
                    'Content-Type': 'application/json',
                },
            };
            return config;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const apiClient = {
    get: (url: string, config?: CustomRequestConfig) => AXIOS.get(url, config),
    post: (url: string, data: unknown, headers?: CustomRequestConfig) => {
        if (headers) {
            return AXIOS.post(url, data, headers);
        }
        return AXIOS.post(url, data);
    },
};
