import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getWalkthroughsThunk } from '@/store/reducers/Walkthroughs/AsyncThunks';
import { useEffect } from 'react';
import { NoData, LoaderDots } from '@/components';
import { StyledPageLoaderContainer } from '@/pages/styled';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkGemoji from 'remark-gemoji';
import emoji from 'remark-emoji';
import { StyledWalkthroughs, StyledContainer } from './styled';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const Walkthroughs = () => {
    const dispatch = useAppDispatch();
    const {
        walkthroughs,
        walkthroughsState: { fetching, error },
    } = useAppSelector((state) => state.Walkthroughs);
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    useEffect(() => {
        if (lab) dispatch(getWalkthroughsThunk(lab.uuid));
    }, [dispatch, lab]);

    const renderWalkthroughs = () => {
        if (fetching) {
            return (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (walkthroughs && walkthroughs.results.length) {
            return (
                <StyledContainer>
                    <StyledWalkthroughs>
                        <Markdown
                            remarkPlugins={[
                                remarkGfm,
                                remarkBreaks,
                                emoji,
                                remarkGemoji,
                            ]}
                            components={{
                                img: ({ node, ...props }) => (
                                    <img
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                        }}
                                        {...props}
                                        alt={props.alt || ''}
                                    />
                                ),
                                code({
                                    node,
                                    inline,
                                    className,
                                    children,
                                    ...props
                                }: any) {
                                    const match = /language-(\w+)/.exec(
                                        className || ''
                                    );
                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            skipHtml={true}
                                            style={duotoneDark as any}
                                            language={match ? match[1] : null}
                                            {...props}
                                        >
                                            {children}
                                        </SyntaxHighlighter>
                                    ) : (
                                        <code className={className} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                            }}
                        >
                            {walkthroughs.results[0].text}
                        </Markdown>
                    </StyledWalkthroughs>
                </StyledContainer>
            );
        }
        return (
            <NoData
                title="This tab usually contains walkthrough or theory for the lab. Unfortunately, there are no materials available for this lab (yet)"
                text={error?.code === 402 ? error?.message : undefined}
                buttons={
                    error?.code === 402
                        ? [
                              {
                                  title: 'Subscribe',
                                  action() {
                                      window.open(
                                          process.env.REACT_APP_PAYMENT_URL,
                                          '_blank'
                                      );
                                  },
                              },
                          ]
                        : undefined
                }
            />
        );
    };
    return <>{renderWalkthroughs()}</>;
};
