import { IStep, IAnchors } from './types';

export const steps: IStep[] = [
    {
        step: 1,
        text: [
            {
                text: 'Become a',
                type: 'simple',
            },
            {
                text: ' security engineer',
                type: 'accent',
            },
            {
                text: ' with',
                type: 'simple',
            },
            {
                text: ' our labs',
                type: 'accent',
            },
        ],
    },
    {
        step: 2,
        text: [
            {
                text: 'Each lab requires you to complete ',
                type: 'simple',
            },
            
            {
                text: ' hands-on tasks',
                type: 'accent',
            },
        ],
    },
    {
        step: 3,
        text: [
            {
                text: 'Take ',
                type: 'simple',
            },
            {
                text: ' a course ',
                type: 'accent',
            },
            {
                text: 'that ',
                type: 'simple',
            },
            {
                text: ' guides you ',
                type: 'accent'
            },
            {
                text: 'through the core concepts of security',
                type: 'simple',
            },


        ],
    },
    {
        step: 4,
        text: [
            {
                text: 'Use our labs to ',
                type: 'simple',
            },
            {
                text: ' upskill yourself',
                type: 'accent',
            },
            {
                text: ' or conduct ',
                type: 'simple',
            },
            {
                text: 'interviews',
                type: 'accent',
            },

        ],
    },
];

export const links: { [key: string]: IAnchors } = {
    main: {
        next: {
            name: 'Whom',
            link: '#whom',
        },
        prev: undefined,
    },
    whom: {
        next: {
            name: 'Polygon',
            link: '#polygon',
        },
        prev: {
            name: 'Defbox',
            link: '#main',
        },
    },
    polygon: {
        next: {
            name: 'Inside',
            link: '#inside',
        },
        prev: {
            name: 'Whom',
            link: '#whom',
        },
    },
    inside: {
        next: {
            name: 'Offer',
            link: '#offer',
        },
        prev: {
            name: 'Polygon',
            link: '#polygon',
        },
    },
    offer: {
        prev: {
            name: 'Inside',
            link: '#inside',
        },
        next: undefined,
    },
};
