import styled from 'styled-components';
import { baseColors, typography } from '../../constants/main';

export const StyledPopUp = styled.div`
    max-width: 804px;
    margin: 0 auto;
    background: ${baseColors.card()};
    border: 1px solid rgba(255, 255, 255, 0.06);
    box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.06);
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

`;

export const StyledBckg = styled.div`
    padding-top: 96px;
    background: rgba(18, 13, 33, 0.9);
    position: fixed;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    box-sizing: border-box;
`;

export const StyledHeader = styled.div`
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;
