import { ILabTeamplate } from '@/types/labs';

export const templates: ILabTeamplate[] = [
    {
        id: 2,
        name: 'Vulnerable SSH',
        difficulties_availible: 1,
        attackers: [
            {
                id: 7,
                difficulty: 1,
                description: 'Level: 1 -  SSH Bruteforce',
                playbook: 'SSH Bruteforce SSH Machine',
            },
        ],
        nodes: [
            {
                name: 'SSH',
            },
        ],
        description:
            'An SSH-setup with a password-based authentication. Common SSH passwords will be brute-forced in a matter of hours if exposed. \r\nLearn how to detect and mitigate this basic misconfiguration',
        average_deployment_time_siem: '00:05:31',
        average_deployment_time_no_siem: '00:04:06',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link:
            'https://telegra.ph/Defbox-Vulnerable-SSH-Write-up-03-27',
        useful_links:
            'https://www.geeksforgeeks.org/how-to-use-hydra-to-brute-force-ssh-connections/\r\nhttps://serverfault.com/questions/334448/why-is-ssh-password-authentication-a-security-risk',
    },
    {
        id: 4,
        name: 'Spring4shell',
        difficulties_availible: 2,
        attackers: [
            {
                id: 9,
                difficulty: 2,
                description: 'Level: 2 -  Spring4Shell POC from github',
                playbook: 'Spring4shell exploit',
            },
        ],
        nodes: [
            {
                name: 'Spring4Shell',
            },
        ],
        description:
            'Learn how to detect Spring4Shell vulnerability in the logs and how to mitigate it',
        average_deployment_time_siem: '00:06:58',
        average_deployment_time_no_siem: '00:05:42',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://github.com/NCSC-NL/spring4shell\r\nhttps://learn.snyk.io/lesson/spring4shell/',
    },
    {
        id: 8,
        name: 'Giggle Vault Bank',
        difficulties_availible: 2,
        attackers: [
            {
                id: 12,
                difficulty: 2,
                description:
                    'Level: 2 -  Attacker will dirbust the balancer to find sensitive information to deface the site',
                playbook: 'PWN payments lab',
            },
        ],
        nodes: [
            {
                name: 'PostgreSQL',
            },
            {
                name: 'paymentsbackend',
            },
            {
                name: 'nginxproxypass',
            },
        ],
        description:
            'This lab showcase lateral movement and web vulnerabilities that can lead to data exfiltration. Online bank built with Nginx, django web server and PostgreSQL lab. \r\nUsers can create and pay invoices using this service. ',
        average_deployment_time_siem: '00:08:45',
        average_deployment_time_no_siem: '00:06:51',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/payments_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=543ae7e600d833019c0c59059589d34d691c0b27bc74c724b680da00de292db3',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/payments_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=3f152af03d90bf8cb266bb1b1158c3d20720bea5627a1df173f6c454727120f1',
        active_labs: 0,
        is_premium: true,
        you_can_run: false,
        write_up_link: null,
        useful_links:
            'https://medium.com/smallcase-engineering/web-security-exposed-git-folder-in-production-51ad9484dee0',
    },
    {
        id: 12,
        name: 'DVWA',
        difficulties_availible: 1,
        attackers: [
            {
                id: 16,
                difficulty: 1,
                description: 'Level: 1 -  RCE in /DVWA/vulnerabilities/exec/',
                playbook: 'DVWA RCE',
            },
        ],
        nodes: [
            {
                name: 'DVWA',
            },
        ],
        description:
            'Damn Vulnerable Web Application. This Lab contains multiple vulnerabilities, which defbox exploits. OWASP TOP 10 is covered here.',
        average_deployment_time_siem: '00:04:27',
        average_deployment_time_no_siem: '00:02:40',
        picture_sm: null,
        picture_md: null,
        picture_lg: null,
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://github.com/digininja/DVWA\r\nhttps://github.com/Aftab700/DVWA-Writeup?tab=readme-ov-file#command-injection',
    },
    {
        id: 7,
        name: 'Postgres with default creds',
        difficulties_availible: 1,
        attackers: [
            {
                id: 11,
                difficulty: 1,
                description: 'Level: 1 -  Password brute force',
                playbook: 'Pwn PostgreSQL',
            },
        ],
        nodes: [
            {
                name: 'PostgreSQL',
            },
        ],
        description:
            'Postgresql database accessible from internet with default creds. Such setup will be exploited within few hours in the wild. Learn how to escape database and detect such attacks.',
        average_deployment_time_siem: '00:05:37',
        average_deployment_time_no_siem: '00:03:50',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://book.hacktricks.xyz/network-services-pentesting/pentesting-postgresql#brute-force\r\nhttps://book.hacktricks.xyz/network-services-pentesting/pentesting-postgresql#rce-to-program',
    },
    {
        id: 10,
        name: 'DNS C2 Tunnel',
        difficulties_availible: 2,
        attackers: [
            {
                id: 14,
                difficulty: 2,
                description: 'Level: 2 -  C2 will send commands via DNS',
                playbook: 'PWN via DNS',
            },
        ],
        nodes: [
            {
                name: 'maliciousdns',
            },
            {
                name: 'corporate-dns',
            },
            {
                name: 'corporate-machine',
            },
        ],
        description:
            'Learn how malware hides itself using normal activity. Learn how to detect malware that is using DNS to communicate.There is an infected machine in the lab. Discover how the lab is communicating with the C2 server.\r\n\r\nMade by Gleb.',
        average_deployment_time_siem: '00:08:10',
        average_deployment_time_no_siem: '00:07:53',
        picture_sm: null,
        picture_md: null,
        picture_lg: null,
        active_labs: 0,
        is_premium: true,
        you_can_run: false,
        write_up_link: null,
        useful_links:
            'https://github.com/iagox86/dnscat2\r\nhttps://sliver.sh/docs?name=DNS+C2',
    },
    {
        id: 1,
        name: 'Notes service',
        difficulties_availible: 3,
        attackers: [
            {
                id: 1,
                difficulty: 1,
                description:
                    'Level: 1 -  With a straightforward reverse shell the attacker will try to stop the service',
                playbook: 'Reverse shell & service DoS',
            },
            {
                id: 2,
                difficulty: 2,
                description:
                    'Level: 2 -  Attacker will try to hide his actions and attempt to deface the http server',
                playbook: 'Hidden reverse shell & HTTP deface',
            },
            {
                id: 3,
                difficulty: 3,
                description:
                    'Level: 3 -  Attacker will not use reverse shell, but will receive commands to run via http to deface the service',
                playbook: 'HTTP C2 & service deface',
            },
        ],
        nodes: [
            {
                name: 'PythonRCE',
            },
        ],
        description:
            "This lab showcase how unsafe python code can be used to gain root access. Flask application to securely save and retrieve notes. \r\nOne of it's endpoints can be entrypoint for an RCE, which will be used to establish a reverse shell. Find vulnerable endpoint and create detection rules to mitigate that threat.",
        average_deployment_time_siem: '00:04:50',
        average_deployment_time_no_siem: '00:03:34',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/python_rce_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=47c203e9d5424822920aa49e74397752110aff851b4238dee40ce7ef094c0e92',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/python_rce_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c6d02239b6ae93dbaa8fac450492c0b79fdf129626cc6ac8671fd35e6d1b925e',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://www.imperva.com/learn/application-security/reverse-shell/\r\nhttps://nasbench.medium.com/understanding-detecting-c2-frameworks-hars-682b30f0505c',
    },
    {
        id: 11,
        name: 'CVE-2024-3094 - SSH RCE',
        difficulties_availible: 10,
        attackers: [
            {
                id: 15,
                difficulty: 10,
                description: 'Level: 10 -  XZ exploit',
                playbook: 'PWN xz',
            },
        ],
        nodes: [
            {
                name: 'xzssh',
            },
        ],
        description:
            'Malicious code was discovered in the upstream tarballs of xz, starting with version 5.6.0. Through a series of complex obfuscations, the liblzma build process extracts a prebuilt object file from a disguised test file existing in the source code, which is then used to modify specific functions in the liblzma code\r\n\r\nThe exploit uses sshd to RCE on the system.',
        average_deployment_time_siem: '00:05:05',
        average_deployment_time_no_siem: '00:04:15',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://github.com/amlweems/xzbot/tree/main\r\nhttps://www.openwall.com/lists/oss-security/2024/03/29/4',
    },
    {
        id: 3,
        name: 'Open RSync',
        difficulties_availible: 1,
        attackers: [
            {
                id: 8,
                difficulty: 1,
                description:
                    'Level: 1 -  Attacker will use rsync to add his ssh keys',
                playbook: 'RSync add SSH keys',
            },
        ],
        nodes: [
            {
                name: 'OpenRSync',
            },
        ],
        description:
            'RSync server that has no authentication. Attacker will use that to achieve persistence, detect it.',
        average_deployment_time_siem: '00:04:31',
        average_deployment_time_no_siem: '00:02:49',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://www.netspi.com/blog/technical/network-penetration-testing/linux-hacking-case-studies-part-1-rsync/',
    },
    {
        id: 5,
        name: 'RedisWithoutAuth (Beta)',
        difficulties_availible: 1,
        attackers: [
            {
                id: 10,
                difficulty: 1,
                description: 'Level: 1 -  SSH key uploaded via redis',
                playbook: 'Pwn Redis',
            },
        ],
        nodes: [
            {
                name: 'Redis',
            },
        ],
        description:
            'Redis database with enabled anonymous access. We dont store any data there, but exploitation is still possible.  Write a rule to detect it when everything will go wrong\r\n"Beta" means that attack could be hard (nearly impossible) to detect. We will appreciate any feedback in telegram/discord groups.',
        average_deployment_time_siem: '00:07:09',
        average_deployment_time_no_siem: '00:04:10',
        picture_sm: null,
        picture_md:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_m.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=874dc50118757deae2dfd3c828e2ff07730a06940e9c0d909de8577efaf96195',
        picture_lg:
            'https://defbox.ams3.digitaloceanspaces.com/api/default_l.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO009D8REQTG9YG4C2F6%2F20240422%2Fams3%2Fs3%2Faws4_request&X-Amz-Date=20240422T101411Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=60ced4e27a2b3ee318c288822f62e34f5e2c337422a4db41ce60e35040920e26',
        active_labs: 0,
        is_premium: false,
        you_can_run: true,
        write_up_link: null,
        useful_links:
            'https://redis.io/docs/management/security/#security-model\r\nhttps://book.hacktricks.xyz/network-services-pentesting/6379-pentesting-redis',
    },
];
