import { Terminal as XTerm } from '@xterm/xterm';
import { FitAddon } from '@xterm/addon-fit';
import { ForwardedRef, forwardRef, useEffect, useRef } from 'react';

export const Terminal = forwardRef(
    (
        { onData }: { onData: (data: string) => void },
        ref: ForwardedRef<XTerm>
    ) => {
        const terminalElementRef = useRef<HTMLDivElement>(null);
        const terminalRef = useRef<XTerm>();
        useEffect(() => {
            if (terminalRef.current) {
                terminalRef.current.dispose();
            }
            terminalRef.current = new XTerm({
                fontFamily: 'monospace, Ubuntu Mono, courier-new, courier',
                fontSize: 15,
            });

            terminalRef.current.open(terminalElementRef.current!);
            if (!terminalRef.current || !onData) return;
            terminalRef.current.onData(onData);
        }, [onData]);

        useEffect(() => {
            if (ref) {
                const fitAddon = new FitAddon();
                terminalRef.current!.loadAddon(fitAddon);
                if (typeof ref === 'function') {
                    ref(terminalRef.current!);
                } else {
                    ref.current = terminalRef.current!;
                }

                fitAddon.fit();
            }
        }, [ref]);
        return <div ref={terminalElementRef}></div>;
    }
);
