import React, { forwardRef } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { TemplateCard, CPBanner } from '../../components';
import { ClockIcon } from '@/components';
import poligon_title_img from '../../assets/SVGTitles/secondPagesTtotles/polygon.svg';
import {
    StyledPolygon,
    StyledTitleContainer,
    StyledTitleDescriptionContainerTop,
    StyledTitleDescriptionContainerBottom,
    StyledTitleDescription,
    StyledFootnotLeft,
    StyledFootnotRight,
    StyledTitleImage,
    StyledCardsList,
    StyledSeparator,
    StyledCardListTitleContainer,
    StyledCardListTitle,
    StyledCardListSubitle,
    StyledBanner,
    StyledBannerTitle,
} from './styled';
import { templates } from './constants';
import { baseColors } from '@/constants';

export const Polygon = forwardRef(
    (_, ref: React.ForwardedRef<HTMLDivElement>) => {
        const renderListTitle = () => {
            return (
                <StyledCardListTitleContainer>
                    <StyledCardListTitle>
                        We add new labs on a regular basis
                    </StyledCardListTitle>
                    <StyledCardListSubitle>
                        Here are some of our labs:
                    </StyledCardListSubitle>
                </StyledCardListTitleContainer>
            );
        };
        const renderBannerContent = () => {
            return (
                <StyledBanner>
                    <ClockIcon size={40} />
                    <StyledBannerTitle>
                        We design labs to simulate real cyber incidents,
                        balancing engagement and hands-on practice to help you
                        gain practical cybersecurity skills.
                    </StyledBannerTitle>
                </StyledBanner>
            );
        };
        return (
            <StyledPolygon ref={ref} id="polygon">
                <StyledTitleContainer>
                    <StyledTitleDescriptionContainerTop>
                        <StyledTitleDescription>
                            <p>
                                Each lab includes a set of virtual machines and
                                specific scenarios. Depending on the scenario,
                                your mission is to either protect or attack the
                                system.
                            </p>
                        </StyledTitleDescription>
                        <StyledFootnotLeft />
                    </StyledTitleDescriptionContainerTop>
                    <StyledTitleImage src={poligon_title_img} />
                    <StyledTitleDescriptionContainerBottom>
                        <StyledFootnotRight />
                        <StyledTitleDescription>
                            <p>
                                You have full control over how you interact with
                                the system. Use SSH and other tools to secure
                                the system from attacks. Hack into the system to
                                find and exploit vulnerabilities.
                            </p>
                        </StyledTitleDescription>
                    </StyledTitleDescriptionContainerBottom>
                </StyledTitleContainer>
                <StyledSeparator />
                <StyledCardsList>
                    {renderListTitle()}
                    {templates.map((t, idx) => {
                        if (idx < 6)
                            return (
                                <TemplateCard {...t} isBorder key={nanoid()} />
                            );
                        return null;
                    })}
                    <CPBanner angleRigthSize={32} color={baseColors.primary()}>
                        {renderBannerContent()}
                    </CPBanner>
                    {templates.map((t, idx) => {
                        if (idx >= 6)
                            return (
                                <TemplateCard {...t} isBorder key={nanoid()} />
                            );
                        return null;
                    })}
                </StyledCardsList>
            </StyledPolygon>
        );
    }
);
