import { PageHeader, IconButton, PayIcon, Logo } from '@/components';
import {
    StyledTitleContainer,
    StyledTitle,
} from '@/components/HeadersV/PageHeader/styled';
import { Actions } from '../';
import { ILab, ILabTeamplate } from '@/types';
import { LAB_TYPE } from '@/constants';
import { useAppDispatch } from '@/hooks/redux';
import {
    destroyLabThank,
    destroyWsLabThunk,
} from '@/store/reducers/LabSlice/AsyncThanks';
import {
    runTemplateThank,
    recreateTemplateThank,
    recreateWsTemplateThunk,
    labAddTimeThunk,
    wsLabAddTimeThunk,
} from '@/store/reducers/TemplatesSlice/AsyncThanks';
import { StyledActionDescription, StyledActionContainer } from './styled';
import { HelpButton } from '../HelpButton/HelpButton';
import { useMemo } from 'react';
import { ToastContext } from '@/components/Toast/ToastContext';
import { useContext } from 'react';

interface IProps {
    disabled: ILab['you_can_run']['description'];
    pathname: string;
    type: LAB_TYPE;
    lab?: ILab;
    iCanRun?: boolean;
    currentTemplate?: ILabTeamplate;
    fetchingTabs?: boolean;
    fetchingTitle?: boolean;
}

export const Header = ({
    disabled,
    type,
    pathname,
    lab,
    iCanRun,
    currentTemplate,
    fetchingTabs,
    fetchingTitle,
}: IProps) => {
    const dispatch = useAppDispatch();
    const fetchingHeaderContent = fetchingTabs || fetchingTitle || false;
    const renderLogo = () => {
        if (type === LAB_TYPE.WORKSHOP) {
            return (
                <StyledTitleContainer>
                    <Logo
                        style={{ cursor: 'pointer' }}
                        isTextLogo={false}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(window.location.origin, '_blank');
                        }}
                    />
                    <StyledTitle>{lab?.name}</StyledTitle>
                </StyledTitleContainer>
            );
        }
        return lab?.name;
    };
    const { toast } = useContext(ToastContext);

    const renderActions = () => {
        if (!!disabled) {
            return (
                <StyledActionContainer>
                    <StyledActionDescription>
                        {disabled}
                    </StyledActionDescription>
                    <HelpButton />
                </StyledActionContainer>
            );
        }
        if (iCanRun) {
            return (
                <Actions
                    type={type}
                    lab={lab}
                    runLab={() => {
                        switch (type) {
                            case LAB_TYPE.DEFAULT:
                                if (lab && currentTemplate)
                                    dispatch(
                                        runTemplateThank(currentTemplate.id)
                                    );
                                return;
                            case LAB_TYPE.WORKSHOP:
                                if (lab)
                                    dispatch(recreateWsTemplateThunk(lab.uuid));
                        }
                    }}
                    destroyLab={() => {
                        switch (type) {
                            case LAB_TYPE.DEFAULT:
                                if (lab && currentTemplate) {
                                    dispatch(
                                        destroyLabThank({
                                            template_id: currentTemplate.id,
                                            uuid_lab: lab?.uuid,
                                        })
                                    );
                                }
                                return;
                            case LAB_TYPE.WORKSHOP:
                                if (lab) {
                                    dispatch(destroyWsLabThunk(lab.uuid));
                                }
                        }
                    }}
                    refreshLab={() => {
                        switch (type) {
                            case LAB_TYPE.DEFAULT:
                                if (currentTemplate) {
                                    dispatch(
                                        recreateTemplateThank(currentTemplate)
                                    );
                                }
                                return;
                            case LAB_TYPE.WORKSHOP:
                                if (lab)
                                    dispatch(recreateWsTemplateThunk(lab.uuid));
                        }
                    }}
                    addTime={() => {
                        switch (type) {
                            case LAB_TYPE.DEFAULT:
                                if (lab && currentTemplate) {
                                    dispatch(
                                        labAddTimeThunk({
                                            labId: lab.uuid,
                                            template: currentTemplate,
                                            toast,
                                        })
                                    );
                                }
                                return;
                            case LAB_TYPE.WORKSHOP:
                                if (lab) dispatch(wsLabAddTimeThunk(lab.uuid));
                        }
                    }}
                />
            );
        }
        return (
            <IconButton
                size="M"
                label="Subscribe"
                iconLeft={<PayIcon />}
                onClick={() => {
                    window.open(process.env.REACT_APP_PAYMENT_URL, '_blank');
                }}
            />
        );
    };

    const tabs = useMemo(() => {
        const tempTabs = [
            {
                value: 'Info',
                id: pathname.replace(/\/[aA-zZ]*$/, `/information`),
            },
            {
                value: 'Tasks',
                id: pathname.replace(/\/[aA-zZ]*$/, `/tasks`),
            },
            // {
            //     value: 'Flags',
            //     id: pathname.replace(/\/[aA-zZ]*$/, `/flags`),
            // },
            {
                value: 'Materials',
                id: pathname.replace(/\/[aA-zZ]*$/, `/walkthroughs`),
            },
        ];
        if (lab) {
            return tempTabs;
        }
        return undefined;
    }, [lab, pathname]);

    return (
        <PageHeader
            fetching={fetchingHeaderContent}
            title={renderLogo()}
            tabs={tabs}
            actions={renderActions()}
        />
    );
};
