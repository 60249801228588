import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLabThank } from '@/store/reducers/LabSlice/AsyncThanks';
import { ILabTeamplate } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';
import { ToastContextType } from '@/components/Toast/ToastContext';

interface labIdAndTemplate {
    labId: string;
    template: ILabTeamplate;
    toast: ToastContextType['toast'];
}

export const labAddTimeThunk = createAsyncThunk<
    void,
    labIdAndTemplate,
    { rejectValue: IError }
>(
    'templateSlice/labAddTimeThunk',
    async ({ labId, template, toast }, { rejectWithValue, dispatch }) => {
        try {
            await apiClient.get(`/api/v1/labs/${labId}/add_time/`);
            await dispatch(getLabThank({ templateId: Number(template.id) }));
        } catch (err: unknown) {
            console.log(err);
            if (isAxiosError(err)) {
                if (err.response &&  err.response.data && err.response.data.detail ) {
                    toast({
                        title: err.response.data.detail,
                        type: 'ERROR'
                    });
                }
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);
