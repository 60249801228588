import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ILabTeamplate } from '@/types/labs';
import { StarIcon, PlayIcon, VMIcon, ClockIcon } from '@/components';
import {
    StyledCard,
    StyledTextContainer,
    StyledTitle,
    StyledText,
    StyledConfigList,
    StyledConfigItem,
    StyledConfigWrapper,
    StyledConfigKey,
    StyledConfigValue,
} from './styled';

interface IProps extends ILabTeamplate {
    isBorder: boolean;
}

export const TemplateCard = (props: IProps) => {
    const {
        isBorder,
        name,
        description,
        is_premium,
        nodes,
        average_deployment_time_no_siem,
        average_deployment_time_siem,
        you_can_run,
    } = props;
    const { ref, inView } = useInView({ threshold: 0.5 });
    return (
        <StyledCard
            $isBorder={isBorder}
            ref={ref}
            as={motion.div}
            initial={inView ? { opacity: 0 } : { opacity: 1 }}
            animate={inView ? { opacity: 1 } : { opacity: 0 }}
        >
            <StyledTextContainer>
                <StyledTitle>{name}</StyledTitle>
                <StyledText>{description}</StyledText>
            </StyledTextContainer>
            {/* <StyledConfigList>
                <StyledConfigItem>
                    <StyledConfigWrapper typeOfWrap="value">
                        <StarIcon variant="S" />
                        <StyledConfigKey typeOfWrap="value">
                            Premium lab
                        </StyledConfigKey>
                        <StyledConfigValue>
                            {is_premium ? 'Yes' : 'No'}
                        </StyledConfigValue>
                    </StyledConfigWrapper>
                </StyledConfigItem>
                <StyledConfigItem>
                    <StyledConfigWrapper typeOfWrap="value">
                        <PlayIcon variant="S" />
                        <StyledConfigKey typeOfWrap="value">
                            I can run
                        </StyledConfigKey>
                        <StyledConfigValue>
                            {you_can_run ? 'Yes' : 'No'}
                        </StyledConfigValue>
                    </StyledConfigWrapper>
                </StyledConfigItem>
                <StyledConfigItem>
                    <StyledConfigWrapper typeOfWrap="value">
                        <VMIcon variant="S" />
                        <StyledConfigKey typeOfWrap="value">
                            Number of VM
                        </StyledConfigKey>
                        <StyledConfigValue>{nodes.length}</StyledConfigValue>
                    </StyledConfigWrapper>
                </StyledConfigItem>
                <StyledConfigItem>
                    <StyledConfigWrapper typeOfWrap="value">
                        <ClockIcon variant="S" />
                        <StyledConfigWrapper typeOfWrap="list">
                            <span>Startup time</span>
                            <StyledConfigWrapper typeOfWrap="value">
                                <StyledConfigKey typeOfWrap="list">
                                    Without elastic
                                </StyledConfigKey>
                                <StyledConfigValue>
                                    {average_deployment_time_no_siem}
                                </StyledConfigValue>
                            </StyledConfigWrapper>
                            <StyledConfigWrapper typeOfWrap="value">
                                <StyledConfigKey typeOfWrap="list">
                                    With elastic
                                </StyledConfigKey>
                                <StyledConfigValue>
                                    {average_deployment_time_siem}
                                </StyledConfigValue>
                            </StyledConfigWrapper>
                        </StyledConfigWrapper>
                    </StyledConfigWrapper>
                </StyledConfigItem>
            </StyledConfigList> */}
        </StyledCard>
    );
};
