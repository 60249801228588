import { useEffect, Fragment } from 'react';
import { nanoid } from 'nanoid';
import { Button } from '@/components';
import { ITask, ILab } from '@/types';
import { NoData, LoaderDots } from '@/components';
import { baseColors } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    openHintThunk,
    getHintThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { StyledContainer, StyledFooter } from './styled';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkGemoji from 'remark-gemoji';
import emoji from 'remark-emoji';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface IProps {
    taskId?: ITask['id'];
    uuid?: ILab['uuid'];
}

export const Content = ({ taskId, uuid }: IProps) => {
    const dispatch = useAppDispatch();

    const { hint, messageAboutEmptyHint, hintState, openHintState } =
        useAppSelector((state) => state.TaskSlice);

    useEffect(() => {
        if (taskId && uuid) {
            dispatch(getHintThunk({ taskId, uuid }));
        }
    }, [taskId, uuid, dispatch]);

    const isNoData =
        (!hint || !hint.length) &&
        (!messageAboutEmptyHint || !messageAboutEmptyHint?.length);

    const isFetching = hintState.fetching || openHintState.fetching;

    const renderContent = () => {
        if (isFetching) {
            return (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (isNoData) {
            return (
                <NoData
                    style={{ paddingTop: '32px', paddingBottom: '48px' }}
                    title="You did not open any hints yet"
                    text="You can still solve it on your own"
                    buttons={[
                        {
                            title: 'Get hint',
                            action: () => {
                                if (taskId && uuid)
                                    dispatch(openHintThunk({ taskId, uuid }));
                            },
                        },
                    ]}
                />
            );
        }
        return (
            <>
                {hint?.map((h, idx) => {
                    return (
                        <Fragment key={nanoid()}>
                            <h2>{`Hint ${idx + 1}`}</h2>

                            <Markdown
                                remarkPlugins={[
                                    remarkGfm,
                                    remarkBreaks,
                                    emoji,
                                    remarkGemoji,
                                ]}
                                components={{
                                    img: ({ node, ...props }) => (
                                        <img
                                            style={{
                                                maxWidth: '100%',
                                                height: 'auto',
                                            }}
                                            {...props}
                                            alt={props.alt || ''}
                                        />
                                    ),
                                    code({
                                        node,
                                        inline,
                                        className,
                                        children,
                                        ...props
                                    }: any) {
                                        const match = /language-(\w+)/.exec(
                                            className || ''
                                        );
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                style={duotoneDark as any}
                                                language={
                                                    match ? match[1] : null
                                                }
                                                {...props}
                                            >
                                                {String(children).replace(
                                                    /\n$/,
                                                    ''
                                                )}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code
                                                className={className}
                                                {...props}
                                            >
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            >
                                {`${h.text}`}
                            </Markdown>
                        </Fragment>
                    );
                })}
                {messageAboutEmptyHint && (
                    <p style={{ color: baseColors.critical() }}>
                        {messageAboutEmptyHint}
                    </p>
                )}
            </>
        );
    };
    return (
        <>
            <StyledContainer>{renderContent()}</StyledContainer>
            {!isNoData && (
                <StyledFooter>
                    <Button
                        disabled={isFetching}
                        onClick={() => {
                            if (taskId && uuid) {
                                dispatch(openHintThunk({ taskId, uuid }));
                            }
                        }}
                    >
                        Get New Hint
                    </Button>
                </StyledFooter>
            )}
        </>
    );
};
