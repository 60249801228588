import img_1 from './assets/Group 14675.png';
import img_2 from './assets/image2.png';
import tasks from './assets/tasks.png';
import terminal from './assets/terminal.png'
import siem from './assets/siem.png'
import materials from './assets/materials.png'




export const tabs = [
    {
        value: 'VMs',
        id: 0,
        title: 'Virtual Machines. Only yours. ',
        text: 'Each lab includes a set of virtual machines, such as target servers, SIEM, or attackers. VMs are deployed individually for each user',
        image: img_2,
    },
    {
        value: 'Tasks',
        id: 1,
        title: 'Interactive tasks',
        text: 'Each lab has a set of tasks. Tasks vary, such as finding flags, changing configurations, or finding artifacts in logs. We provide hints with some tasks.',
        image: tasks,
    },
    {
        value: 'SSH',
        id: 2,
        title: 'Your access to lab',
        text: 'Access the SSH console of virtual machines through the Defbox interface, allowing you to manage and interact with each system. ',
        image: terminal,
    },
    {
        value: 'SIEM',
        id: 3,
        title: 'Use SIEM to detect attacks',
        text: 'Some labs are integrated with ELK. ELK is deployed exclusively for you. Defbox connect your own SIEM system upon request.',
        image: siem,
    },
    {
        value: 'Materials',
        id: 4,
        title: 'Theory is important',
        text: 'Labs include theoretical materials to support your learning. We help fill gaps in your theoretical knowledge, but our focus is on hands-on practice.',
        image: materials,
    },
];

export const bannerConfig = {
    opened: {
        filledHeaderWidth: 100,
        filledFooterWidth: 40,
        angleRigthSize: 32,
        angleLeftSize: 32,
        style: {
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            width: '50%',
            height: 'calc(100% + 16px)',
        },
    },
    closed: {
        filledHeaderWidth: 100,
        filledFooterWidth: 100,
        angleRigthSize: 24,
        angleLeftSize: 24,
        style: {
            position: 'absolute',
            top: '-8px',
            right: '-44px',
            width: '80px',
            height: 'calc(100% + 16px)',
        },
    },
    background: {
        filledHeaderWidth: 100,
        filledFooterWidth: 40,
        angleRigthSize: 32,
        angleLeftSize: 32,
        style: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: 'calc(100% + 16px)',
        },
    },
};
