import { css } from 'styled-components';
import { STATUS_LAB, baseColors } from '@/constants';
import { useAppSelector } from '@/hooks/redux';
import { ILab } from '@/types';
import { LAB_TYPE } from '@/constants';
import {
    IconButton,
    PlayIcon,
    StopIcon,
    RefreshIcon,
    ClockIcon,
    Timer,
} from '@/components';
import { Loader } from './Loader.ts/Loader';
import { HelpButton } from '../HelpButton/HelpButton';
import { useTimer } from '../../hooks/useTimer';
import { StyledActions, StyledLoaderContainer } from './styled';

interface IProps {
    type: LAB_TYPE;
    lab?: ILab;
    runLab?: () => void;
    destroyLab?: () => void;
    refreshLab?: () => void;
    runAttack?: (playbookId: number | string) => void;
    addTime?: () => void;
}

export const Actions = ({
    type,
    lab,
    runLab,
    destroyLab,
    refreshLab,
    addTime,
}: IProps) => {
    const { labState, destroyLabState } = useAppSelector(
        (state) => state.LabWithSchemeSlice
    );
    const { runTemplateState, currentTemplateState, recreateTemplateState } =
        useAppSelector((state) => state.TemplatesSlice);

    const fetching =
        labState.fetching ||
        destroyLabState.fetching ||
        runTemplateState.fetching ||
        currentTemplateState.fetching ||
        recreateTemplateState.fetching;

    const endTime = lab?.planned_destroy_date;
    const status = lab?.status;
    const isDisabled =
        status === STATUS_LAB.SET_UP_VM ||
        status === STATUS_LAB.DEPLOY ||
        status === STATUS_LAB.TEAR_DOWN;

    const isAverageTimer =
        status === STATUS_LAB.SET_UP_VM || status === STATUS_LAB.DEPLOY;

    const averageTimer = useTimer({
        start: isDisabled,
        deployDate: lab?.deploy_date,
    });

    const getAverageTimer = () => {
        if (isAverageTimer) {
            return `${averageTimer} / ${
                lab?.recreated
                    ? lab?.average_deployment_time_no_siem?.replace(/^00:/, '')
                    : lab?.average_deployment_time_siem?.replace(/^00:/, '')
            }`;
        }
        return '';
    };

    if (status === STATUS_LAB.DEACTIVATED && type === LAB_TYPE.DEFAULT) {
        const deployButtonStyled = css`
            height: 100%;
            background: ${baseColors.primary()};
            box-sizing: border-box;
            padding: 0 16px;
        `;
        return (
            <StyledActions>
                <IconButton
                    stylePropContainer={deployButtonStyled}
                    size="M"
                    label="Deploy"
                    iconLeft={<PlayIcon />}
                    onClick={() => {
                        if (runLab) runLab();
                    }}
                />
                <HelpButton offsetLeft={false} />
            </StyledActions>
        );
    }
    if (status === STATUS_LAB.OPERATIONAL || isDisabled) {
        return (
            <StyledActions>
                {isDisabled && (
                    <StyledLoaderContainer>
                        <Loader status={status} text={getAverageTimer()} />
                    </StyledLoaderContainer>
                )}
                {status === STATUS_LAB.OPERATIONAL && endTime && (
                    <Timer endTime={endTime} />
                )}
                {type === LAB_TYPE.DEFAULT && (
                    <IconButton
                        size="M"
                        label="Destroy"
                        disabled={isDisabled || fetching}
                        iconLeft={<StopIcon />}
                        onClick={() => {
                            if (destroyLab) destroyLab();
                        }}
                    />
                )}
                <IconButton
                    size="M"
                    label="Recreate"
                    disabled={isDisabled || fetching}
                    iconLeft={<RefreshIcon />}
                    onClick={() => {
                        if (refreshLab) refreshLab();
                    }}
                />
                {type === LAB_TYPE.DEFAULT && (
                    <IconButton
                        size="M"
                        label="Add time"
                        disabled={isDisabled || fetching}
                        iconLeft={<ClockIcon />}
                        onClick={() => {
                            if (addTime) addTime();
                        }}
                    />
                )}

                <HelpButton />
            </StyledActions>
        );
    }
    return <></>;
};
